/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    blockquote: "blockquote",
    a: "a",
    pre: "pre",
    code: "code",
    br: "br",
    hr: "hr"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: Expressions evaluate to a value, they return that value.\nStatements do not.")), "\n", React.createElement(_components.p, null, "Statements are instructions that ", React.createElement(_components.em, null, "do"), " something, they don’t return a value.\nExpressions ", React.createElement(_components.em, null, "evaluate to a value"), ", they return that value."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Rust is an expression-oriented language.\nThis means that most things are expressions, and evaluate to some kind of value.\nHowever, there are also statements."), "\n", React.createElement("footer", null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/steveklabnik"
  }, "Steve Klabnik"), " (member of the Rust core\nteam)")), "\n"), "\n", React.createElement(_components.p, null, "Assigning a value to a variable is a statement, it doesn’t return anything."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let num = 5;\n")), "\n", React.createElement(_components.p, null, "Expressions can be part of a statement."), "\n", React.createElement(_components.p, null, "While the line above is a statement, it contains an expression (something that evaluates to a value).\nIn this case, the value itself, the integer ", React.createElement(_components.code, null, "5"), "."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Variable assignment being a statement is the reason you can’t assign a value to the result of another assignment.\nYou can in many other languages, not in Rust."), React.createElement(_components.p, null, "The following snippet would try to assign “nothing” to the ", React.createElement(_components.code, null, "also_num"), " variable, that’s an error!"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let also_num = (let num = 5); // error!\n"))), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Statements technically return something. Rust’s way to express “nothing here”, the empty tuple ", React.createElement(_components.code, null, "()"), ".")), "\n", React.createElement(_components.p, null, "Function bodies are made up of a series of statements, optionally ending in an expression."), "\n", React.createElement(_components.p, null, "Expressions do not include ending semicolons.\nIf you add a semicolon to the end of an expression, you turn it into a statement, which will then not return a value."), "\n", React.createElement(_components.p, null, "If a function ends in an expression, it returns the value of that expression."), "\n", React.createElement(_components.p, null, "A function definition is a statement, it does not result in a value.\nCalling a function is an expression, that expression evaluates to whatever that function call returns."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let num = add(4, 1);\n\nfn add(x: i32, y:i32) -> i32 {\n    x + y\n}\n")), "\n", React.createElement(_components.p, null, "The lines where the ", React.createElement(_components.code, null, "add"), " function is defined, are a statement, those lines don’t evaluate to anything.", React.createElement(_components.br), "\n", "Calling ", React.createElement(_components.code, null, "add(4, 1)"), " is an expression, it evaluates to a value (the integer ", React.createElement(_components.code, null, "5"), ").", React.createElement(_components.br), "\n", "Inside the function, the last line of the function body is an expression ", React.createElement(_components.code, null, "x + y"), ".", React.createElement(_components.br), "\n", "That ending expression evaluates to a value and the function returns it."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "If that line ended in a semicolon instead (ie. ", React.createElement(_components.code, null, "x + y;"), "), it would turn the expression into a statement.\nThat would not return a value, causing the function to not return a value."), React.createElement(_components.p, null, "That would mean we lied when we defined the function, as we stated it would return an integer of type ", React.createElement(_components.code, null, "i32"), " (with the syntax ", React.createElement(_components.code, null, "-> i32"), ").\nThe function doesn’t do that anymore.\nIt returns nothing now."), React.createElement(_components.p, null, "This is a bug and the Rust compiler won’t let you do this.")), "\n", React.createElement(_components.p, null, "The value ", React.createElement(_components.code, null, "add(4, 1)"), " evaluated to is then assigned to the variable named ", React.createElement(_components.code, null, "num"), ".\nThat entire line (ie. ", React.createElement(_components.code, null, "let num = add(4, 1);"), ") is a statement."), "\n", React.createElement(_components.p, null, "A codeblock that creates a new scope (ie. ", React.createElement(_components.code, null, "{}"), ") is an expression (it evaluates to a value)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let num = {\n    let x = 4;\n    x + 1\n};\n")), "\n", React.createElement(_components.p, null, "Inside that codeblock, a variable declaration statement happens, followed by an expression (", React.createElement(_components.code, null, "x + 1"), ").\nThe value that last expression evaluates to will be the value the entire codeblock evaluates to.\nThat value is then assigned to the ", React.createElement(_components.code, null, "num"), " variable in this example."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "You may explicitly return a value from a function by using the ", React.createElement(_components.code, null, "return"), " keyword followed by an expression.", React.createElement(_components.br), "\n", "By convention, that line is terminated with a semicolon.\nAs you might expect, that isn’t mandatory and the compiler won’t complain if you leave it off."), "\n", React.createElement(_components.p, null, "This is an ", React.createElement(_components.code, null, "add"), " function that explicitly ends execution and returns a value is equivalent to the ", React.createElement(_components.code, null, "add"), " above that ended with an expression."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn add(x: i32, y:i32) -> i32 {\n    return x + y;\n}\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "We can use this mechanism to make code that has a variable declaration that is only used to later be populated a bit shorter."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "example.rs"
  }, "fn main() {\n    let num = 5;\n    let mut name = \"\";\n    if num > 3 {\n        name = \"Tom\";\n    } else {\n        name = \"Jerry\";\n    }\n    println!(\"{}\", name);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "if"), " is an expression, it returns a value."), "\n", React.createElement(_components.p, null, "The value an ", React.createElement(_components.code, null, "if"), " evaluates to is the value of the codeblock it executed.\nThat means we can rewrite our example above."), "\n", React.createElement(_components.p, null, "Now, ", React.createElement(_components.code, null, "name"), " is never an empty string, it’s either ", React.createElement(_components.code, null, "\"Tom\""), " or ", React.createElement(_components.code, null, "\"Jerry\""), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "example.rs"
  }, "fn main() {\n    let num = 5;\n    let name = if num > 3 {\n        \"Tom\"\n    } else {\n        \"Jerry\"\n    };\n    println!(\"{}\", name);\n}\n")), "\n", React.createElement(_components.p, null, "This means that every arm of the ", React.createElement(_components.code, null, "if"), " statement must return the same type.\nIf we return different types from the branches, the compiler won’t be able to figure out what the resulting type of the entire ", React.createElement(_components.code, null, "if"), " is and will show a compilation error."), "\n", React.createElement(_components.p, null, "A similar approach can be taken with more programming constructs in Rust that are expressions: like ", React.createElement(_components.code, null, "match"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
